import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";

import { AuthGuard } from "./Authentication/auth.guard";
import { PagenotfoundComponent } from "./pages/pagenotfound/pagenotfound.component";

export const AppRoutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./Authentication/login/login.module").then((m) => m.LoginModule),
  },

  {
    path: "login",
    loadChildren: () =>
      import("./Authentication/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "map",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/map/map/map.module").then((m) => m.MapModule),
  },
  {
    path: "adminyard",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/adminayrd/adminyard.module").then(
        (m) => m.AdminyardModule
      ),
  },

  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/permission/permission.module").then(
            (m) => m.PermissionModule
          ),
      },
      {
        path: "spotterdrive",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/spotterdrive/spotterdrive.module").then(
            (m) => m.SpotterdriveModule
          ),
      },
      {
        path: "configuration",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/configuration/configuration.module").then(
            (m) => m.ConfigurationModule
          ),
      },
      {
        path: "reports",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/report/report.module").then((m) => m.ReportModule),
      },
      {
        path: "inventory",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/inventory/inventory.module").then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: "gate",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/gate/gate.module").then((m) => m.GateModule),
      },
      {
        path: "appointment",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./pages/appointment/appointmentschedule/appointment.module"
          ).then((m) => m.AppointmentModule),
      },
      {
        path: "carrier",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/carrier/carrier.module").then((m) => m.CarrierModule),
      },

      {
        path: "mapFeature/maps",
        canActivate: [AuthGuard],

        loadChildren: () =>
          import("./pages/maps/maps.module").then((m) => m.MapsModule),
      },

      {
        path: "drivertask",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./pages/drivertaskmanagment/drivertask/drivertask.module"
          ).then((m) => m.DriverTaskModule),
      },

      {
        path: "mapFeature/forms",

        loadChildren: () =>
          import("./pages/forms/forms.module").then((m) => m.Forms),
      },
      {
        path: "mapFeature/tables",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/tables/tables.module").then((m) => m.TablesModule),
      },
      {
        path: "device",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/device/device.module").then((m) => m.DeviceModule),
      },

      {
        path: "help",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/help/help.module").then((m) => m.HelpModule),
      },
      {
        path: "yard",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/yard/yard.module").then((m) => m.YardModule),
      },
      {
        path: "yardviewmap",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/yardviewmap/yardviewmap.module").then(
            (m) => m.YardviewmapModule
          ),
      },
      {
        path: "task",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/drivertaskmanagment/task/task.module").then(
            (m) => m.TaskModule
          ),
      },

      {
        path: "yard/task",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pages/task/task.module").then((m) => m.TaskModule),
      },
      {
        path: "trailerlist",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./pages/yard/editequipmentlist/editequipmentlist.module"
          ).then((m) => m.EditEquipmentlistModule),
      },
    ],
  },

  {
    path: "**",
    component: PagenotfoundComponent,
  },
];

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  apiUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getMenuList(id: any) {
    // let defaultParams = new HttpParams()
    //   .set("role", roles == undefined ? 0 : roles)
    //   .set("siteId", id);
    return this.http.get(this.apiUrl + "menu/getSideBar/" + id);
    // http://localhost:8080/menu/getSideBar/1
    // http://localhost:8080/menu/getSideBarForSite?siteId=1,2&role=1,3
    // http://localhost:8080/atlyms/menu/getSideBar
    // http://localhost:8080/menu/getSideBarForSite?siteIds&roles
  }
}

import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, switchMap, throwError } from "rxjs";
import { AlertService } from "./alert.service";
import { AuthService } from "../Authentication/auth.service";
import { environment } from "src/environments/environment";
import { SharedService } from "./shared.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  apiUrl = environment.baseUrl;
  constructor(
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private http: HttpClient,
    private sharedService: SharedService
  ) {}

  private handleError(error: HttpErrorResponse) {
    return throwError(() => new Error(error.status + error.statusText));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.authService.getToken();
    let tokenizedreq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next.handle(tokenizedreq).pipe(
      catchError((error) => {
        // If the error is due to an expired token, try refreshing the token
        if (error.status === 401) {
          let input = {
            refreshToken: this.authService.getRefreshToken(),
          };
          console.log("input", input);
          let siteID = sessionStorage.getItem("siteId");
          console.log("input", input);
          let defaultParams = new HttpParams().set("siteId", siteID);
          // this.alertService.alert("Session has been refreshing !", "info");
          // this.alertService.alert("Session has been refreshing !", "info");
          return this.http
            .post(this.apiUrl + "user/refreshToken", input, {
              params: defaultParams,
            })
            .pipe(
              switchMap((res: any) => {
                if (res.metadata.code == 200) {
                  let newAccessToken = res.result.acessToken;
                  console.log(
                    "res.result.refreshToken",
                    res.result.refreshToken
                  );

                  sessionStorage.removeItem("refreshToken");
                  sessionStorage.setItem(
                    "refreshToken",
                    res.result.refreshToken
                  );
                  sessionStorage.removeItem("token");
                  sessionStorage.setItem("token", res.result.acessToken);
                  console.log("tokenServiceRefreshToken", newAccessToken);

                  return next.handle(
                    req.clone({
                      setHeaders: {
                        Authorization: `Bearer ${newAccessToken}`,
                      },
                    })
                  );
                } else {
                  this.alertService.alert(
                    "Your session has expired. Please log in again to continue !!!",
                    "info"
                  );
                  this.authService.onLogOut();
                  setTimeout(() => {
                    location.reload();
                  }, 1000);
                }
              })
            );
        } else {
          this.alertService.alert("Something went wrong !!", "error");
          this.router.navigateByUrl("login");
          this.sharedService.hideLoader();
          // this.authService.onLogOut();
          // setTimeout(() => {
          //   location.reload();
          // }, 1000);
        }
        return throwError(error);
      })
    );
  }
}

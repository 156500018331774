import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";
import { SidebarService } from "../services/sidebar.service";
import { SharedService } from "../services/shared.service";
import { AuthService } from "../Authentication/auth.service";
import { environment } from "src/environments/environment";
import { CsvService } from "../services/Csvv.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  icontype?: string;
  childrens?: subChildrenItemsss[];
}
export interface subChildrenItemsss {
  path: string;
  title: string;

  type?: string;
}

//Menu Items
export const ROUTES: any[] = [];
// {
//   path: "/dashboard",
//   title: "Dashboard",
//   type: "link",
//   icontype: "dashboard",
//   collapse: null,
//   children: [],
// },
// {
//   path: "/map",
//   title: "Yard",
//   type: "link",
//   icontype: "local_shipping",
//   collapse: null,
//   children: [],
// },
// {
//   path: "/adminyard",
//   title: "Admin Yard",
//   type: "link",
//   icontype: "local_shipping",
//   collapse: null,
//   children: [],
// },
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  userName: string;
  miniSidebar: boolean = false;
  ps: any;
  baseUrlImage = environment.baseUrlImage;
  calculateDistanceMenuItem = document.querySelector(
    'a[href="/mapFeature/set"]'
  );
  constructor(
    private sidebarService: SidebarService,
    private sharedService: SharedService,
    private authService: AuthService,
    private csvService: CsvService,
    private router: Router
  ) {}
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    let siteData = JSON.parse(sessionStorage.getItem("siteDetails"));
    let roles = JSON.parse(sessionStorage.getItem("role"));
    console.log("roles=========>", roles);
    let roleId = roles.map((x) => {
      return x.id;
    });
    console.log("roleId=========>", roleId);
    console.log(
      'sessionStorage.getItem("siteId"),sessionStorage.getItem("siteId")',
      sessionStorage.getItem("siteId")
    );

    let data = siteData.filter((x) => {
      if (x.id == sessionStorage.getItem("siteId")) {
        return x;
      }
    });
    console.log("data===>", data);

    // console.log("IsminimizeSidebar", this.sharedService.getMiniSidebarStatus());
    // let sampleFlag = this.sharedService.getMiniSidebarStatus();
    // console.log("sampleFlag", sampleFlag);
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
    if (roleId == 3) {
      this.getMenuList(0);
    } else {
      this.getMenuList(data[0].id);
    }

    this.userName = this.authService.getUserName();
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];
    sessionStorage.setItem("IsminimizeSidebar", misc.sidebar_mini_active);
    this.sharedService.setMiniSidebarStatus(misc.sidebar_mini_active);

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      this.miniSidebar = false;
      console.log("this.miniSidebar", this.miniSidebar);
      // event.preventDefault();
      this.csvService.buttonvalue.next("false");
      misc.sidebar_mini_active = false;
    } else if (misc.sidebar_mini_active == undefined) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      this.csvService.buttonvalue.next("true");

      setTimeout(function () {
        body.classList.add("sidebar-mini");
        this.miniSidebar = true;
        // event.preventDefault();

        console.log("this.miniSidebar", this.miniSidebar);

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  onEdit(event: Event) {
    event.preventDefault(); // Prevents the default form submission behavior
    this.csvService.buttonvalue.next("edit");
  }
  getMenuList(id: any) {
    this.sharedService.showLoader();
    this.sidebarService.getMenuList(id).subscribe((res: any) => {
      console.log("getMenuList", res);
      this.sharedService.hideLoader();

      this.menuItems = res;
      // this.menuItems.push({
      //   title: "Logout",
      //   type: "link",
      //   icontype: "logout",
      //   collapse: null,
      //   children: [],
      // });
      console.log("this.menuItems", this.menuItems);
    });
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  expandOrCollapseMenu(id) {
    let parent = document.getElementById(id + "-p");
    let child = document.getElementById(id);

    parent.ariaExpanded = parent.ariaExpanded === "true" ? "false" : "true";

    child.style.height =
      child.style.height === "0px" || child.style.height === "" ? "100%" : "0";
  }

  onLogout() {
    Swal.fire({
      title: "Are you sure that you want to Log Out?",
      // text: "You want to log out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2f80ed",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        this.router.navigateByUrl("login");
      }
    });
  }
  onClickmarkersetting(title) {
    console.log("vedant", title);

    if (title == "Marker Setting") {
      this.csvService.opensettingModal.next(true);
    } else if (title == "Calculate Distance") {
      this.csvService.openCDModal.next(true);
    }
  }
}
